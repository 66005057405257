import { useState, useEffect } from "react";
import { PlusCircle, Trash2, Edit2, Save, X, Search } from "lucide-react";
import { HOST } from "../../config";
import Sidebar from "../../Components/Sidebar";
import { fetchCategories } from "../../api/Helper";
import { nameToSlug } from "../../Utilities/Methords";

export default function Categories() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    name: "",
    imageUrl: "",
    description: "",
  });
  const [editingId, setEditingId] = useState(null);
  const [editValues, setEditValues] = useState({
    name: "",
    imageUrl: "",
    description: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    async function populateCategories() {
      try {
        setLoading(true);
        const shortedCategories = await fetchCategories();
        setCategories(shortedCategories);
      } catch (err) {
        setError("Failed to fetch categories");
      } finally {
        setLoading(false);
      }
    }
    populateCategories();
  }, []);

  const handleAddCategory = async () => {
    if (newCategory.name.trim() === "") return;
    if (
      categories.some(
        (cat) =>
          cat.name.toLowerCase() === newCategory.name.trim().toLowerCase()
    )
    ) {
      setError("Category already exists");
      return;
    }

    try {
      setIsAdding(true);
      const request = await fetch(`${HOST}/api/v1/admin/category`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: newCategory.name.trim(),
          slug : nameToSlug(newCategory.name.trim()),
          imageUrl: newCategory.imageUrl.trim(),
          description: newCategory.description.trim(),
        }),
      });
      const response = await request.json();

      setCategories(
        [...categories, response].sort((a, b) => a.name.localeCompare(b.name))
      );
      setNewCategory({ name: "", imageUrl: "", description: "" });
      setError("");
      setSuccessMessage("Category added successfully");
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add category");
    } finally {
      setIsAdding(false);
    }
  };

  const handleEditCategory = async (id) => {
    if (editValues.name.trim() === "") return;
    if (
      categories.some(
        (cat) =>
          cat.id !== id &&
          cat.name.toLowerCase() === editValues.name.trim().toLowerCase()
      )
    ) {
      setError("Category name already exists");
      return;
    }

    try {
      await fetch(`${HOST}/api/v1/admin/category/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: editValues.name.trim(),
          slug : nameToSlug(editValues.name.trim()),
          imageUrl: editValues.imageUrl.trim(),
          description: editValues.description.trim(),
        }),
      });

      const updatedCategories = categories
        .map((cat) =>
          cat.id === id
            ? {
                ...cat,
                name: editValues.name.trim(),
                imageUrl: editValues.imageUrl.trim(),
                description: editValues.description.trim(),
              }
            : cat
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      setCategories(updatedCategories);
      setEditingId(null);
      setEditValues({ name: "", imageUrl: "", description: "" });
      setError("");
      setSuccessMessage("Category updated successfully");
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update category");
    }
  };

  const handleDeleteCategory = async (id) => {
    if (!window.confirm("Are you sure you want to delete this category?"))
      return;
    try {
      await fetch(`${HOST}/api/v1/admin/category/${id}`, { method: "DELETE" });
      setCategories(categories.filter((cat) => cat.id !== id));
      setError("");
      setSuccessMessage("Category deleted successfully");
    } catch (err) {
      setError(err.response?.data?.message || "Failed to delete category");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && newCategory.name.trim() !== "") {
      handleAddCategory();
    }
  };

  const handleEditKeyPress = (e) => {
    if (e.key === "Enter" && editValues.name.trim() !== "") {
      handleEditCategory(editingId);
    }
    if (e.key === "Escape") {
      setEditingId(null);
    }
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startEdit = (category) => {
    setEditingId(category.id);
    setEditValues({
      name: category.name,
      imageUrl: category.imageUrl || "",
      description: category.description || "",
    });
  };

  return (
    <div className="flex bg-gray-50">
      <Sidebar />
      <div className="flex-1 transition-all duration-300 ease-in-out ml-0">
        <div className="m-2 max-h-screen bg-white rounded-xl shadow-lg overflow-scroll">
          <div className="border-b border-gray-200 bg-gray-50 px-4 py-2">
            <h1 className="text-2xl font-bold text-gray-900">
              Manage Categories
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Add, edit, or remove Blog Categories
            </p>
          </div>

          <div className="p-6 space-y-6">
            {error && (
              <div className="flex items-center p-4 bg-red-50 border-l-4 border-red-400 rounded-md">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {successMessage && (
              <div className="flex items-center p-4 bg-green-50 border-l-4 border-green-400 rounded-md">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-green-700">{successMessage}</p>
                </div>
              </div>
            )}

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 p-2 border-4 border-grey-600 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Search categories..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="space-y-1">
              <input
                type="text"
                className="w-full rounded-lg p-2 border-4 border-grey-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={newCategory.name}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, name: e.target.value })
                }
                onKeyPress={handleKeyPress}
                placeholder="Category name"
              />
              <input
                type="text"
                className="w-full rounded-lg p-2 border-4 border-grey-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={newCategory.imageUrl}
                onChange={(e) =>
                  setNewCategory({ ...newCategory, imageUrl: e.target.value })
                }
                placeholder="Image URL"
              />
              <textarea
                className="w-full rounded-lg p-2 border-4 border-grey-600 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={newCategory.description}
                onChange={(e) =>
                  setNewCategory({
                    ...newCategory,
                    description: e.target.value,
                  })
                }
                placeholder="Description"
                rows="3"
              />
              <button
                onClick={handleAddCategory}
                disabled={isAdding || newCategory.name.trim() === ""}
                className="w-full px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300 disabled:cursor-not-allowed transition-colors duration-200"
              >
                <PlusCircle className="h-5 w-5 inline-block mr-2" />
                Add Category
              </button>
            </div>

            {loading ? (
              "Loading..."
            ) : (
              <div className="space-y-3">
                {filteredCategories.map((category) => (
                  <div
                    key={category.id}
                    className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-200"
                  >
                    {editingId === category.id ? (
                      <div className="space-y-3">
                        <input
                          type="text"
                          className="w-full px-3 py-2 border-gray-200 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                          value={editValues.name}
                          onChange={(e) =>
                            setEditValues({
                              ...editValues,
                              name: e.target.value,
                            })
                          }
                          onKeyUp={handleEditKeyPress}
                          autoFocus
                          placeholder="Category name"
                        />
                        <input
                          type="text"
                          className="w-full px-3 py-2 border-gray-200 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                          value={editValues.imageUrl}
                          onChange={(e) =>
                            setEditValues({
                              ...editValues,
                              imageUrl: e.target.value,
                            })
                          }
                          placeholder="Image URL"
                        />
                        <textarea
                          className="w-full px-3 py-2 border-gray-200 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                          value={editValues.description}
                          onChange={(e) =>
                            setEditValues({
                              ...editValues,
                              description: e.target.value,
                            })
                          }
                          placeholder="Description"
                          rows="3"
                        />
                        <div className="flex gap-2 justify-end">
                          <button
                            onClick={() => handleEditCategory(category.id)}
                            className="p-2 text-green-600 hover:bg-green-50 rounded-md transition-colors duration-200"
                            title="Save"
                          >
                            <Save className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => setEditingId(null)}
                            className="p-2 text-gray-600 hover:bg-gray-200 rounded-md transition-colors duration-200"
                            title="Cancel"
                          >
                            <X className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-2">
                        <div className="flex items-center justify-between">
                          <span className="text-gray-900 font-medium">
                            {category.name}
                          </span>
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => startEdit(category)}
                              className="p-2 text-blue-600 hover:bg-blue-50 rounded-md transition-colors duration-200"
                              title="Edit"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDeleteCategory(category.id)}
                              className="p-2 text-red-600 hover:bg-red-50 rounded-md transition-colors duration-200"
                              title="Delete"
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </div>
                        </div>
                        {category.imageUrl && (
                          <img
                            src={category.imageUrl}
                            alt={category.name}
                            className="w-20 h-20 object-cover rounded-md"
                          />
                        )}
                        {category.description && (
                          <p className="text-sm text-gray-600">
                            {category.description}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {filteredCategories.length === 0 && !loading && (
                  <div className="text-center py-8">
                    <div className="text-gray-400">
                      {searchTerm
                        ? "No matching categories found"
                        : "No categories yet"}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
