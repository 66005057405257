const NotificationType = {
    SUCCESS: 'success',
    ERROR: 'error',
    DELETE: 'delete'
  };
  
  const NotificationColors = {
    [NotificationType.SUCCESS]: {
      bg: 'bg-green-50',
      border: 'border-green-400',
      text: 'text-green-700',
      icon: 'text-green-400'
    },
    [NotificationType.ERROR]: {
      bg: 'bg-blue-50',
      border: 'border-blue-400',
      text: 'text-blue-700',
      icon: 'text-blue-400'
    },
    [NotificationType.DELETE]: {
      bg: 'bg-red-50',
      border: 'border-red-400',
      text: 'text-red-700',
      icon: 'text-red-400'
    }
  };
  
  export default function Notification  ({ type, message }) {
    const colors = NotificationColors[type];
    
    return message ? (
      <div className={`flex items-center p-4 ${colors.bg} border-l-4 ${colors.border} rounded-md`}>
        <div className="flex-shrink-0">
          <svg className={`h-5 w-5 ${colors.icon}`} viewBox="0 0 20 20" fill="currentColor">
            {type === NotificationType.SUCCESS && (
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" />
            )}
            {(type === NotificationType.ERROR || type === NotificationType.DELETE) && (
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" />
            )}
          </svg>
        </div>
        <div className="ml-3">
          <p className={`text-sm ${colors.text}`}>{message}</p>
        </div>
      </div>
    ) : null;
  };