import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

const RichTextEditor = ({ placeholder, initialContent = "", newContent }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (initialContent) setContent(initialContent);
  }, [initialContent]);

  const config = useMemo(() => ({
    readonly: false,
    placeholder: placeholder || "Start wring your Article here...",

    // Only show specifically requested buttons
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "paragraph",
      "font",
      "fontsize",
      "|",
      "brush",
      "lineHeight",
      "|",
      "image",
      "video",
      "table",
      "|",
      "ul",
      "ol",
      "|",
      "hr",
      "align",
      "|",
      "spellcheck",
      "link",
    ],

    // Remove all other buttons and features
    removeButtons: [
      "source",
      "eraser",
      "copyformat",
      "indent",
      "outdent",
      "superscript",
      "subscript",
      "symbol",
      "print",
      "about",
      "dots",
      "preview",
      "find",
      "file",
      "selectall",
      "cut",
      "copy",
      "undo",
      "redo",
      "classSpan",
      "script",
      "mic",
      "speech",
    ],

    controls: {
      font: {
        list: {
          "Bona Nova": "Bona Nova",
          "Afta Serif": "Afta Serif",
        },
        // exec: (editor, current, { control }) => {
        //   if (control.args && control.args[0]) {
        //     const key = control.args[0];
        //     editor.selection.focus();
        //     editor.execCommand("fontName", false, key);
        //   }

        //   function a(editor) {
        //     try {
        //       const current = editor.selection.current();
        //       if (current) {
        //         const font = window
        //           .getComputedStyle(current)
        //           .fontFamily.split(",")[0]
        //           .replace(/['"]/g, "");
        //         return font || "Bona Nova"; // Return the selected font or default font
        //       }
        //     } catch (e) {
        //       console.error("Font template error:", e);
        //     }
        //     return "Bona Nova"; // Default font when no selection
        //   }
        //   a(editor);
        // },
        // data: { currentValue: "Bona Nova" },

        // // Template that gets the current font applied to the selected text
        // template: (editor, current, control) => {
        //   let currentFont = "Bona Nova"; // default font
        //   console.log(editor);

        //   try {
        //     // Get the current range
        //     const range = editor.selection.range;

        //     // Check if we have a valid selection
        //     if (range && !range.collapsed) {
        //       // Get the common ancestor container of the selection
        //       const container = range.commonAncestorContainer;
        //       const element =
        //         container.nodeType === 3 ? container.parentNode : container;

        //       // Get computed style
        //       if (element && element.style) {
        //         const computedFont = window
        //           .getComputedStyle(element)
        //           .getPropertyValue("font-family")
        //           .split(",")[0]
        //           .replace(/['"]/g, "");

        //         currentFont = computedFont || "Bona Nova";
        //       }
        //     } else {
        //       // If no selection, get the current cursor position element
        //       const currentElement = editor.selection.current();
        //       if (currentElement) {
        //         const computedFont = window
        //           .getComputedStyle(currentElement)
        //           .getPropertyValue("font-family")
        //           .split(",")[0]
        //           .replace(/['"]/g, "");

        //         currentFont = computedFont || "Bona Nova";
        //       }
        //     }
        //   } catch (e) {
        //     console.error("Font template error:", e);
        //   }

        //   return currentFont;
        // },

        // // Custom popup that updates the list based on the selection
        // popup: (editor, current, self, close) => {
        //   const list = self.list;
        //   const container = editor.create.div("jodit-font-list");

        //   Object.keys(list).forEach((fontName) => {
        //     const item = editor.create.div("jodit-font-item");
        //     item.innerHTML = `<span style="font-family: ${fontName}">${list[fontName]}</span>`;
        //     item.addEventListener("click", () => {
        //       self.exec(editor, current, {
        //         control: { args: [fontName] },
        //       });
        //       close();
        //     });
        //     container.appendChild(item);
        //   });

        //   return container;
        // },
      },

      fontsize: {
        list: [
          "8",
          "10",
          "12",
          "14",
          "16",
          "18",
          "20",
          "24",
          "28",
          "32",
          "36",
          "48",
        ],
        // template: (editor, key, value) => {
        //   return `<span style="font-size: ${value}px">${value}</span>`;
        // },
        // tooltip: "Font size",
        // isActiveChild: (editor, control, button) => {
        //   const current = editor.selection.current();
        //   if (current) {
        //     const size = current.style.fontSize;
        //     return size ? parseInt(size) : "14";
        //   }
        //   return "14";
        // },
      },
      paragraph: {
        iconURL:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQziHnxjgQaMwhdDIxntzyWogyUbKhwm-sXfw&s",
        list: {
          p: "Pharagraph",
          h1: "Heading 1",
          h2: "Heading 2",
          h3: "Heading 3",
          h4: "Heading 4",
          h5: "Heading 5",
          h6: "Heading 6",
          blockquote: "Quote",
          div: "Div",
          pre: "Source code",
        },
        // template: (editor, key, value) => {
        //   return `<${key}>${value}</${key}>`;
        // },
        // tooltip: "Paragraph Format",
        // isActiveChild: (editor, control, button) => {
        //   const current = editor.selection.current();
        //   if (current) {
        //     const parentTag = editor.selection.current().tagName.toLowerCase();
        //     return parentTag || "p";
        //   }
        //   return "p";
        // },
      },
    },

    // Disable unnecessary plugins
    disablePlugins: [
      "speech-recognize",
      "speech",
      "drag-and-drop",
      "drag-and-drop-element",
      "tune",
    ],

    // Font settings
    fonts: {
      Arial: "Arial",
      "Times New Roman": "Times New Roman",
      Helvetica: "Helvetica",
      Georgia: "Georgia",
      "Courier New": "Courier New",
      Verdana: "Verdana",
    },

    // Hide unnecessary UI elements
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    statusbar: false,

    // Basic editor settings
    height: 500,
    toolbarSticky: true,

    // Table settings
    table: {
      rows: 3,
      cols: 3,
    },

    // Image settings
    image: {
      dialogWidth: 600,
      closeAfterInsert: true,
    },

    // Video settings
    video: {
      dialogWidth: 600,
      closeAfterInsert: true,
    },

    // Enable simple file upload
    uploader: {
      insertImageAsBase64URI: false,
    },
  }),
  [placeholder]
);

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={(newContent) => setContent(newContent)}
      onChange={newContent}
    />
  );
};

export default RichTextEditor;
