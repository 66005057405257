import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-2xl text-gray-600 mb-4">Page Not Found</p>
        <div className="bg-blue-600 rounded py-2 px-8">
          <a href="/" className="text-white hover:underline">
            Go back to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
