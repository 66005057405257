import { host } from "../config";

export async function getUsers() {
    try {
        const response = await fetch(`${host}/api/v1/admin/users`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        return [];
    }
};



export const fetchBrokersLinks = () => {
    return new Promise((resolve, reject) => {
        fetch(`${host}/api/v1/website/brokersLing`)
            .then(response => {
                if (!response.ok) {
                    return [];
                }
                return response.json();
            })
            .then(data => resolve(data))
            .catch(error => reject(error));
    });
};

export const fetchContactUsQueries = () => {
    return new Promise((resolve, reject) => {
        fetch(`${host}/api/v1/admin/contact_us_queries`)
            .then(response => {
                if (!response.ok) {
                    return [];
                }
                return response.json();
            })
            .then(data => resolve(data))
            .catch(error => reject(error));
    });
};

export function postBrokers(brokers) {
    return new Promise((resolve, reject) => {
        fetch(`${host}/api/v1/admin/brokers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(brokers),
        })
            .then(response => {
                if (!response.ok) {
                    reject();
                }
                resolve()
            })
            .catch(error => {
                console.error('Failed to post brokers:', error);
                reject();
            });
    });
}


export async function fetchCategories() {
    try {
      const request = await fetch(`${host}/api/v1/admin/category`);
      const responce = await request.json();
      return responce.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    } catch (err) {
      throw err;
    } 
  };