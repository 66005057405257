import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Screens/Login/Login';
import PrivateRoute from './Routes/PrivateRoute';
import BrokerLinks from './Screens/BrokerLinks/BrokerLinks';
import ContactUs from './Screens/ContactUs/ContactUs';
import Categories from './Screens/Categories/Categories';
import Blogs from './Screens/Blogs/Blogs';
import WorkInProgress from './Screens/WordInProgess.jsx/WordInProgess';
import PageNotFound from './Screens/PageNotFound/PageNotFound';
import Blog from './Screens/Blogs/Blog/Blog';
import Users from './Screens/Users/Users';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<WorkInProgress />} />
        <Route path="/broker_links" element={<BrokerLinks />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/new_post" element={<Blog />} />
        <Route path="/pages/:type" element={<Blogs />} />
        <Route path="/pages/:saveType/:blog_id" element={<Blog />} />
        <Route path="/themes" element={<WorkInProgress />} />
        <Route path="/users" element={<Users />} />
        <Route path="/" element={<PrivateRoute />}>
      </Route>
      <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
